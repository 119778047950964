import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
`;

const formatVimeoUrl = (video) => {
  const arrVideo = video.embed_url.split('/');
  return `https://player.vimeo.com/video/${arrVideo[3]}`
}

function Video({ video, ...props }) {  
  return (
    <VideoWrapper>
      <iframe
        src={formatVimeoUrl(video)}
        style={{ left: 0, top: 0, height: '100%', width: '100%', position: 'absolute' }}
        title={video.title}
        // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allow="autoplay; fullscreen"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        width="100%"
        height="700vw"
      />
    </VideoWrapper>
  );
}

export default Video;
